.InputLabel.container {
  margin-bottom: 15px;
}

.InputLabel p {
  color: var(--labelColor);
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

.InputLabel input {
  width: 300px;
  height: 22px;
  border-color: var(--inputBorder);
  border-width: 2px;
  border-style: solid;
  border-image: none;
  padding: 2px;
} 